<template>
<div>
  <div class="barra"></div>

  <div class="row">
    <div class="col- col-sm-12 col-md-8 col-lg-5 col-xl-4">
      <div class="card cardr text-center border-primary mb-3">
        <div class="card-header">
          <h5>Registrate y comienza a estudiar</h5>
        </div>

        <div class="card-body">
          <form method="POST" action>
            <div class="form-group row">
              <div class="col">
                <input
                  id="name"
                  placeholder="Nombre completo"
                  type="text"
                  class="form-control"
                  name="name"
                  v-model="name"
                  value
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <input
                  id="email"
                  placeholder="Correo electronico"
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="email"
                  required 
                  autofocus/>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <input
                  id="password"
                  placeholder="Contraseña"
                  type="password"
                  class="form-control"
                  name="password"
                  v-model="password"
                  required
                />
              </div>
            </div>

            <div class="alert alert-warning" v-if="res" role="alert">
              {{ res }}
            </div>
            <div class="form-group">
            <div v-if="validationErrors">
                  <ul class="alert alert-warning">
                      <li v-for="(value, index) in validationErrors" :key="index">{{ value[0] }}</li>
                  </ul>
              </div>
            </div>
            <div class="form-group">
              <ul class="alert alert-warning" v-if="submited">
                  <li v-if="submited && !$v.name.required">Se requiere un nombre de usuario.</li>
                  <li v-if="submited && !$v.name.minLength">Su nombre de usuario debe tener al menos 6 caracteres.</li>
                  <li v-if="submited && !$v.name.maxLength">Su nombre de usuario debe tener máximo 32 caracteres.</li>
                  <li v-if="submited && !$v.email.email">Correo electrónico no valido.</li>
                  <li v-if="submited && !$v.email.required">Se requiero un correo electrónico.</li>
                  <li v-if="submited && !$v.email.maxLength">Su correo electronico debe tener máximo 32 caracteres</li>
                  <li v-if="submited && !$v.password.required">Se requiero una contraseña.</li>
                  <li v-if="submited && !$v.password.minLength">Su contraseña debe tener al menos 6 caracteres.</li>
                  <li v-if="submited && !$v.password.maxLength">Su contraseña debe tener máximo 32 caracteres</li>
              </ul>
            </div>
            <div class="form-group">
              <small class="form-text text-muted">
                Al registrarte, aceptas nuestras condiciones de uso y política de privacidad.
              </small>
            </div>
            <div class="form-group">
                <button class="btn btn-primary btn-block btnsend"
                  @click="register">
                  Register
                </button>
            </div>
          </form>
        </div>

        <div class="card-footer text-muted">
          ¿Ya tienes una cuenta?
          <router-link to="/login">Inicia sesión</router-link>
        </div>
      </div>
    </div>
    <div class="col mensajer col-sm-12 col-md-6 col-lg-6 col-xl-8">
      <div hidden class="float-right">
        <h1 class="display-4">Lorem ipsum dolor sit.</h1>
        <p class="lead float-right">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </p>
        <hr class="my-2" />
        <p class="float-right">Lorem.</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { required, minLength, email, maxLength } from 'vuelidate/lib/validators'

export default {
  name: "register",
  data: () => ({
    name: "",
    email: "",
    password: "",
    checkbox: false,
    res: '',
    validationErrors: '',
    submited:false
  }),
  validations: {
    name: {
      required,
      minLength: minLength(4),
      maxLength: maxLength(30),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32)
    },
    email: {
      required,
      email,
      maxLength: maxLength(32)
    }
  },
  methods: {
    register(e) {
      e.preventDefault();
      this.submited = true;
      if(this.$v.$invalid){
        return false;
      }
      var btnSend = document.querySelector(".btnsend");
      btnSend.setAttribute("disabled", "");
      this.res = '';
      this.$store
      .dispatch("setRegister", {
        name: this.name,
        email: this.email,
        password: this.password,
      })
      .then(() => {
        this.$router.push({ name: "Courses" });
      })
      .catch((error) => {
        btnSend.removeAttribute("disabled");
        if (error.response.status == 422){
          this.validationErrors = error.response.data.errors;
        }  
        this.submited = false;
      });
    },
  },
};
</script>

<style>
.barra {
  height: 100px;
}
</style>
