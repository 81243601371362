<template>
<div class="container">
        <register />
  </div>
</template>
<script>
import register from '@/components/web/session/register.vue'

export default {
  name: 'sessionRegister',
  components: {
    register
  },
}
</script>
